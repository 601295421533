/* IMPORT */
import hex from './rgba.js'; // Alias
import rgb from './rgba.js'; // Alias
import rgba from './rgba.js';
import hsl from './hsla.js'; // Alias
import hsla from './hsla.js';
import toKeyword from './to_keyword.js';
import toHex from './to_hex.js';
import toRgba from './to_rgba.js';
import toHsla from './to_hsla.js';
import channel from './channel.js';
import red from './red.js';
import green from './green.js';
import blue from './blue.js';
import hue from './hue.js';
import saturation from './saturation.js';
import lightness from './lightness.js';
import alpha from './alpha.js';
import opacity from './alpha.js'; // Alias
import contrast from './contrast.js';
import luminance from './luminance.js';
import isDark from './is_dark.js';
import isLight from './is_light.js';
import isTransparent from './is_transparent.js';
import isValid from './is_valid.js';
import saturate from './saturate.js';
import desaturate from './desaturate.js';
import lighten from './lighten.js';
import darken from './darken.js';
import opacify from './opacify.js';
import fadeIn from './opacify.js'; // Alias
import transparentize from './transparentize.js';
import fadeOut from './transparentize.js'; // Alias
import complement from './complement.js';
import grayscale from './grayscale.js';
import adjust from './adjust.js';
import change from './change.js';
import invert from './invert.js';
import mix from './mix.js';
import scale from './scale.js';
/* EXPORT */
export { 
/* CREATE */
hex, rgb, rgba, hsl, hsla, 
/* CONVERT */
toKeyword, toHex, toRgba, toHsla, 
/* GET - CHANNEL */
channel, red, green, blue, hue, saturation, lightness, alpha, opacity, 
/* GET - MORE */
contrast, luminance, isDark, isLight, isTransparent, isValid, 
/* EDIT - CHANNEL */
saturate, desaturate, lighten, darken, opacify, fadeIn, transparentize, fadeOut, complement, grayscale, 
/* EDIT - MORE */
adjust, change, invert, mix, scale };
